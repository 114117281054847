import React from "react"
import { graphql } from 'gatsby'
import { settings } from '../_shared/settings'

// componenents
import Layout from "../components/Layout"
import ProductList from "../components/ProductList"
import { DiscountNotice } from "../components/DiscountNotice"
import { ProductListByDate } from "../_shared/ProductList/ByDate"
import { homepageDisplayModeOptions, useGetSiteSettings } from "../_shared/hooks/useGetSiteSettings"
import DiscountFormConditionalDisplay from "../components/forms/DiscountRegistration/DiscountFormConditionalDisplay"
import { DevLinks } from "../components/DevLinksBox"

const IndexPage = ({ data }) => {
  const ss = useGetSiteSettings()
  // const products = data.allProductType.nodes

  // console.log('products: (#23sdf) ', data)
  const productlistNode = data.allContentfulProductList.edges[0].node
  const products = productlistNode.products
  console.log ('products [#hj7]: ', products)

  const dates = data.allContentfulEventDate.nodes
  console.log ('dates [#hj7]: ', dates)
  
  const title = 'Welcome!'
  const bodyText = (
    <>
      We are a social community of travellers, international students, backpackers and local aussies. We focus on providing affordable
      sightseeing trips and free events. We keep our website simple, so you can find what you want quickly.
      <br /><br />(Please note: we use {settings.booking_platform_name} as our ticketing system.)
    </>
  )

  const RenderProductsList = () => {
    // const newdates = useBuildDates(dates)

    // return <ProductList products={products} />
    if (ss.homepageDisplayMode == homepageDisplayModeOptions.allEventTypes ) {
      return <ProductList products={products} />
    } else {
      return <ProductListByDate dates={dates} />
    }
  }
  
  return (
    <Layout>
      <DevLinks />
      <DiscountFormConditionalDisplay regPage="home" />
      <DiscountNotice />
      <h2>{title}</h2>

      <div>
        {bodyText}
      </div>
      <RenderProductsList dates={dates} />
      
    </Layout>
  )
}

export default IndexPage

// '3u3dEjdBFzTkudIzN7pECx' is the 'homepage' ProductList entry id (for contentful)
// todo: use hooks instead: useGetHomepageProductsList and useUpcomingEvents? (check this)
export const query = graphql`
query GetAllOtaProducts {
  allContentfulEventDate (sort: {fields: startDateTime, order: ASC}) {
    nodes {
      startDateTime
      endDateTime
      repeat
      overrides {
        cancelled
        startDateTime
      }
      productType {
        ... on ContentfulProduct {
          id
          name
          descriptionShort
          sellPrice
          durationInMinutes
          urlSlug {
            productName
            contentful_id
            startLocation {
              contentful_id
              parentLocation {
                title
                id
                contentful_id
                parentLocation {
                  contentful_id
                  id
                  title
                  parentLocation {
                    contentful_id
                    id
                    title
                  }
                }
              }
              id
              title
            }
          }
          featureImage {
            gatsbyImageData
            title
          }
        }
      }
    }
  }
  allContentfulProductList(
    filter: {contentful_id: {eq: "3u3dEjdBFzTkudIzN7pECx"}}
  ) {
    edges {
      node {
        name
        products {
          ... on ContentfulProduct {
            id
            name
            descriptionShort
            sellPrice
            durationInMinutes
            urlSlug {
              productName
              contentful_id
              startLocation {
                contentful_id
                parentLocation {
                  title
                  id
                  contentful_id
                  parentLocation {
                    contentful_id
                    id
                    title
                    parentLocation {
                      contentful_id
                      id
                      title
                    }
                  }
                }
                id
                title
              }
            }
            featureImage {
              gatsbyImageData
              title
            }
          }
        }
        contentful_id
        id
      }
    }
  }
}
`